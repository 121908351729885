<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'Meetings' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Meetings
          </v-btn>
          <h2 class="darkGrey--text d-flex flex-column custom-section-title">
            <span>Items of <span class="font-weight-black">Meeting Name</span></span>
            <p class="text-body-1">for Board Name</p>
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Item to this meeting
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex align-center">
        <v-checkbox
          color="secondary"
          label="Show only my items"
          hide-details
          class="d-flex mr-6 custom-checkbox-small"
        ></v-checkbox>
        <v-checkbox
          color="secondary"
          label="Display Alias"
          hide-details
          class="d-flex mr-6 custom-checkbox-small"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="3"
      >
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemSample"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.title="{ item }">
              <span class="py-6 d-flex">
                <span class="text-caption">
                  {{ item.title.slice(0,textCut) }}
                  <v-tooltip
                    top
                    class="tooltip-no-opacity"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.title.length > textCut"
                        width="24"
                        height="18"
                        min-width="24"
                        min-height="18"
                        class="ml-2 white--text"
                        depressed
                        color="lightGrey"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-caption">
                      <v-card elevation="0" width="600" color="transparent" class="white--text pa-4">
                        {{ item.title }}
                      </v-card>
                    </span>
                  </v-tooltip>
                </span>
              </span>
            </template>
            <template v-slot:item.board="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.board }}
                </span>
              </span>
            </template>
            <template v-slot:item.meeting="{ item }">
              <span class="d-flex py-6">
                {{ item.meeting }}
              </span>
            </template>
            <template v-slot:item.date="{ item }">
              <span class="d-flex py-6 text-caption">
                {{ item.date }}<br>
                {{ item.time }}<br>
                {{ item.weekday }}
              </span>
            </template>
            <template v-slot:item.tags="{ item }">

              <v-tooltip top v-if="item.tags.length>3">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.tags.length }}
                  </v-chip>
                </template>
                <span class="custom-tooltip-list">
                  <v-row>
                    <v-col>
                      <span class="custom-tooltip-title">
                        TAGS
                      </span>
                      <ul>
                        <li v-for="item in item.tags" :key="item">
                          {{ item }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </span>
              </v-tooltip>
              <span v-if="item.tags.length<=3" class="d-flex flex-column">
                <span v-for="item in item.tags" :key="item">
                  {{ item }}
                </span>
              </span>

            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-center">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewItem(item)"
                    >
                      <v-icon size="20">
                        mdi-file-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Cover Sheet Draft
                  </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="itemAttachments"
                    >
                      <v-icon size="20">
                        mdi-paperclip
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Attachments
                  </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="viewItem(item)"
                    >
                      <v-icon size="20">
                        mdi-eye-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    View details
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuMeeting"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <item-create v-model="modals.create"></item-create>
    <item-edit v-model="modals.edit"></item-edit>
    <item-type v-model="modals.itemType"></item-type>
    <item-change-region v-model="modals.region"></item-change-region>
    <item-cover-sheet v-model="modals.coverSheet"></item-cover-sheet>
    <item-share-permissions v-model="modals.permissions"></item-share-permissions>
    <item-conversations-notes v-model="modals.conversations"></item-conversations-notes>
    <item-private v-model="modals.private"></item-private>
    <item-move-to-meeting v-model="modals.move"></item-move-to-meeting>
    <item-not-private v-model="modals.notPrivate"></item-not-private>
    <item-delete v-model="modals.delete"></item-delete>
    <item-view v-model="modals.view" v-if="modals.view"></item-view>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import ItemCreate from '@/components/Items/ItemCreate'
import ItemEdit from '@/components/Items/ItemEdit'
import ItemType from '@/components/Items/ItemType'
import ItemChangeRegion from '@/components/Items/ItemChangeRegion'
import ItemCoverSheet from '@/components/Items/ItemCoverSheet'
import ItemSharePermissions from '@/components/Items/ItemSharePermissions'
import ItemConversationsNotes from '@/components/Items/ItemConversationsNotes'
import ItemPrivate from '@/components/Items/ItemPrivate'
import ItemMoveToMeeting from '@/components/Items/ItemMoveToMeeting'
import ItemNotPrivate from '@/components/Items/ItemNotPrivate'
import ItemDelete from '@/components/Items/ItemDelete'
import ItemView from '@/components/Items/ItemView'

export default Vue.extend({
  name: 'MeetingItems',
  components: {
    Portal,
    ItemCreate,
    ItemEdit,
    ItemType,
    ItemChangeRegion,
    ItemCoverSheet,
    ItemSharePermissions,
    ItemConversationsNotes,
    ItemPrivate,
    ItemMoveToMeeting,
    ItemNotPrivate,
    ItemDelete,
    ItemView
  },
  data: () => ({
    modeType: '',
    textCut: 400,
    breadcrumbsItems: [
      {
        text: 'Meetings',
        disabled: false,
        href: ''
      },
      {
        text: 'Items of Meeting Name',
        disabled: true,
        href: '/boards'
      }
    ],
    modals: {
      create: false,
      edit: false,
      itemType: false,
      region: false,
      coverSheet: false,
      permissions: false,
      conversations: false,
      private: false,
      download: false,
      move: false,
      notPrivate: false,
      delete: false,
      view: false
    },
    itemsMenuMeeting: [
      { icon: 'mdi-pencil', text: 'Edit Title / Alias / Tags', actions: 'edit' },
      { icon: 'mdi-format-list-bulleted-type', text: 'Item Type', actions: 'itemType' },
      { icon: 'mdi-table', text: 'Change Region', actions: 'region' },
      { icon: 'mdi-file', text: 'Cover Sheet', actions: 'coverSheet' },
      { icon: 'mdi-share', text: 'Share permissions', actions: 'permissions' },
      { icon: 'mdi-message-text-outline', text: 'Conversations and Notes', actions: 'conversations' },
      { icon: 'mdi-lock-outline', text: 'Set as private', actions: 'private' },
      { icon: 'mdi-download', text: 'Download Draft', actions: 'download' },
      { icon: 'mdi-undo-variant', text: 'Move to Another Meeting', actions: 'move' },
      { icon: 'mdi-lock-open-variant-outline', text: 'Not private', type: 'important', actions: 'notPrivate' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    itemsMenuParked: [
      { icon: 'mdi-pencil', text: 'Unfinalize', actions: 'unfinalize' },
      { icon: 'mdi-download', text: 'Download Agenda', actions: 'download' },
      { icon: 'mdi-download', text: 'Download Packet', actions: 'download' },
      { icon: 'mdi-download', text: 'Download Minutes', actions: 'download' }
    ],
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Tags', value: 'tags', width: '150px', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    itemSample: [
      {
        title: 'Proin sed purus arcu. Nam ut elementum felis, vel convallis neque. Maecenas ultricies euismod urna vel suscipit. Aenean velit sem, posuere nec mauris in, interdum condimentum nisl. Donec sodales accumsan lacus id dignissim. Mauris sed odio lacus. Nullam arcu nulla, ultrices ac malesuada quis, euismod in turpis. Mauris vel facilisis ex, nec imperdiet tellus. Maecenas vel felis tincidunt, cursus diam ut, vehicula orci. Aliquam vestibulum, turpis quis scelerisque molestie, sapien ipsum laoreet velit, ut vehicula magna urna sit amet eros. Suspendisse faucibus condimentum lectus non vehicula. Morbi vehicula augue risus, dignissim cursus enim sollicitudin a. Aliquam et arcu justo.\n' +
          '\n' +
          'Duis congue turpis eu efficitur commodo. Maecenas accumsan consequat ante at semper. In eu facilisis ex. Donec convallis pellentesque est in semper. Integer ac ipsum vitae quam porta sollicitudin. Aliquam ut massa tempor, pretium metus et, gravida ante. Quisque faucibus et mi ut placerat. Sed vitae enim eget nisl lobortis sodales. Nullam ut nisi ligula. Curabitur vel elementum tellus. Vivamus sit amet est ut augue rutrum suscipit ac et neque. Mauris a massa ante. Vestibulum viverra feugiat diam, vitae vestibulum mi feugiat faucibus. Cras facilisis massa sem, sed rhoncus nisl pellentesque id. Praesent laoreet mauris eget interdum congue.',
        date: 'Sep 25,2020',
        time: '7:00PM',
        weekday: 'Tuesday',
        tags: [
          'Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5', 'Tag 6'
        ]
      },
      {
        title: 'Aenean velit sem, posuere nec mauris in, interdum condimentum nisl. Donec sodales accumsan lacus id dignissim.',
        date: 'Sep 25,2020',
        time: '7:00PM',
        weekday: 'Tuesday',
        tags: [
          'Tag 1', 'Tag 2'
        ]
      }
    ],
    boardsSample: ['City Manager Office', 'City Secretary Office', 'EDC Board', 'Town Board', 'Architectural Review Board', 'Planning and Zoning Commission', 'Library Board'],
    boardSelected: 'City Manager Office'
  }),
  methods: {
    test () {
      console.log(1)
    },
    viewItem (item) {
      console.log(item)
      this.modals.view = true
    },
    openModal (item, optional) {
      if (optional) this.modeType = optional
      // this.modals.edit = true
      this.modals[item] = true
    },
    itemAttachments () {
      this.$router.push({ name: 'ItemAttachments', params: { meeting: true } })
    }
  }
})
</script>

<style>
  .v-menu__content {
    overflow-y: unset !important;
    overflow-x: unset !important;
    contain: unset !important;
  }
</style>
